import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [2,4];

export const dictionary = {
		"/(auth)": [5,[2]],
		"/account/(redirect-logged-in-users)/fdhjd46tdgFPKlHgf324dfasfhQW-0dO34T": [28,[4]],
		"/account/(redirect-logged-in-users)/login": [29,[4]],
		"/account/reset-password": [31],
		"/account/reset-password/confirm": [32],
		"/account/verify-email": [33],
		"/account/(redirect-logged-in-users)/verify": [30,[4]],
		"/(auth)/(app)/(authenticated)/admin": [6,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/lesson-history": [7,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/school-calendar": [8,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/student-availability": [9,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/teacher-availability": [10,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/teacher-calendar": [11,[2,3]],
		"/(auth)/(app)/(authenticated)/admin/teacher-lesson-history": [12,[2,3]],
		"/(auth)/(app)/(authenticated)/availability": [13,[2,3]],
		"/confirm": [34],
		"/(auth)/(app)/(authenticated)/dashboard": [14,[2,3]],
		"/(auth)/(app)/(authenticated)/group-lessons": [15,[2,3]],
		"/(auth)/(app)/(authenticated)/home": [16,[2,3]],
		"/(auth)/(app)/(authenticated)/my-todo-lists": [17,[2,3]],
		"/(auth)/payment/success": [27,[2]],
		"/(auth)/(app)/(authenticated)/private-lessons": [18,[2,3]],
		"/(auth)/(app)/(authenticated)/scheduling": [19,[2,3]],
		"/(auth)/(app)/(authenticated)/student-availability-old": [22,[2,3]],
		"/(auth)/(app)/(authenticated)/student-availability": [21,[2,3]],
		"/(auth)/(app)/(authenticated)/student": [20,[2,3]],
		"/(auth)/(app)/(authenticated)/subject": [23,[2,3]],
		"/(auth)/(app)/(authenticated)/teacher-availability": [25,[2,3]],
		"/(auth)/(app)/(authenticated)/teacher-calendar": [26,[2,3]],
		"/(auth)/(app)/(authenticated)/teacher": [24,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';